export default {
  root: '/',
  auth: {
    signup: '/signup',
    signin: '/signin',
    signout: '/signout',
    forgotPassword: '/forgot-password',
    forgotPasswordConfirm: '/forgot-password-confirm',
    forgotPasswordConfirmLegacy: '/reset-password-confirm',
    deleteAccount: '/delete-account',
    deleteAccountVerification: '/delete-account/verification',
    deleteAccountSuccess: '/delete-account-success',
    google: (env) => {
      let baseURL;
      switch (env) {
        case 'production':
          baseURL = 'https://news.spotlightnews.us';
          break;
        case 'staging':
          baseURL = 'https://news.stg.spotlightnews.us';
          break;
        case 'development':
          baseURL = 'https://news.dev.spotlightnews.us';
          break;
        default:
          break;
      }

      return `${baseURL}/users/auth/google_oauth2`;
    },
    apple: (env) => {
      let baseURL;
      switch (env) {
        case 'production':
          baseURL = 'https://news.spotlightnews.us';
          break;
        case 'staging':
          baseURL = 'https://news.stg.spotlightnews.us';
          break;
        case 'development':
          baseURL = 'https://news.dev.spotlightnews.us';
          break;
        default:
          break;
      }

      return `${baseURL}/users/auth/apple`;
    },
  },
  app: {
    getApp: '/get-app-redirect',
  },
  interest: {
    feed: (id) => `/interest/${id}`,
  },
  news: {
    main: '/news',
    feed: (id) => `/news/${id}`,
    article: (id) => `/articles/${id}`,
    sharedArticle: (id) => `/shares/articles/${id}`,
    topArticles: '/top-articles',
  },
  onboard: {
    main: '/onboard',
    interests: '/onboard/interests',
  },
  publishers: {
    main: (slug) => `/publishers/${slug}/news`,
    topic: (slug, topicId) => `/publishers/${slug}/news/${topicId}`,
    article: (slug, articleId) => `/publishers/${slug}/articles/${articleId}`,
    about: (slug) => `/publishers/${slug}/about`,
    categories: (slug) => `/publishers/${slug}/categories`,
    recent: (slug) => `/publishers/${slug}/recent`,
    members: (slug) => `/publishers/${slug}/members`,
  },
  writers: {
    main: (slug) => `/writers/${slug}/news`,
  },
  emailConfirmation: '/email-confirmation',
  discover: '/discover',
  myLibrary: '/my-library',
  myStats: '/my-stats',
  myStatsWebViewToken: '/my-stats-web-view-validate',
  myStatsWebView: '/my-stats-web-view',
  allPublishers: '/all-publishers',
  profile: '/profile',
  notifications: '/notifications',
  bookmarks: '/bookmarks',
  favorites: '/liked',
  downloadHistory: '/download-history',
  read: '/recently-read',
  premium: '/premium',
  premiumPurchase: (priceId) => `/premium-purchase/${priceId}`,
  premiumConfirmation: '/premium-confirmation',
  terms: 'https://www.spotlightnews.us/terms.html',
  privacyPolicy: 'https://www.spotlightnews.us/privacy.html',
  downloadFromAppStore: 'https://itunes.apple.com/us/app/spotlight/id1291820344',
};
