import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';

const MyLibraryIcon = ({ color = '#ffffff', mobile = false }) => (
  <Container
    style={{
      display: 'inline-block',
      width: 30,
      padding: 0,
      margin: mobile ? '0px 15px 0px 0px' : 0,
      marginRight: 10,
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      id="vector"
    >
      <path id="path" d="M 29.9 427.891 L 486.4 427.891 L 486.4 477.791 L 29.9 477.791 Z" fill={color} />
      <path id="path_1" d="M 254.443 74.8 L 304.343 74.8 L 304.343 381.6 L 254.443 381.6 Z" fill={color} />
      <path id="path_2" d="M 154.643 74.8 L 204.543 74.8 L 204.543 381.6 L 154.643 381.6 Z" fill={color} />
      <path id="path_3" d="M 54.843 174.6 L 104.743 174.6 L 104.743 381.6 L 54.843 381.6 Z" fill={color} />
      <path id="path_4" d="M 330.812 200.925 L 374.025 175.975 L 477.525 355.237 L 434.312 380.187 Z" fill={color} />
    </svg>

  </Container>
);

MyLibraryIcon.propTypes = {
  color: PropTypes.string,
  mobile: PropTypes.bool,
};

export default MyLibraryIcon;
