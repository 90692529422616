import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Bookmark as BookmarkIcon,
  Favorite as FavoriteIcon,
  Restore as RestoreIcon,
  Help as HelpIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
  WbSunny,
  NightsStay,
} from '@material-ui/icons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  Box,
  Divider,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Typography,
  IconButton,
  Switch,
} from '@material-ui/core';
import tinycolor from 'tinycolor2';
import GetAppIcon from '@material-ui/icons/GetApp';
import Brightness6Icon from '@material-ui/icons/Brightness6';
import routes from '../../../routes';
import Avatar from '../Avatar';
import Link from '../../common/Link';
import EditProfileDialog from '../EditProfileDialog';
import SettingsDialog from '../SettingsDialog';
import ThemeContext from '../../../context/theme/ThemeContext';
import { useQuery } from '../../../hooks/useQuery';
import PremiumIcon from '../../common/PremiumIcon/PremiumIcon';
import SpotlightLogo from '../../common/SpotlightLogo';
import ActiveIcon from '../../Premium/ActiveIcon';
import { getSettings, updateSettings } from '../../../actions/user';

const styles = makeStyles((theme) => ({
  avatarItemRoot: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: 24,
    backgroundColor: (props) => props?.color,
  },
  avatarRoot: {
    marginRight: 14,
  },
  avatarNameTypography: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '18px',
    color: (props) => {
      if (!props?.color) return theme.palette.text.primary;
      return tinycolor(props?.color).isLight() ? '#000000' : '#ffffff';
    },
  },
  avatarEmailTypography: {
    fontSize: '14px',
    color: (props) => {
      if (!props?.color) return theme.palette.text.primary;
      return tinycolor(props?.color).isLight() ? '#000000' : '#ffffff';
    },
  },
  brandTypography: {
    marginTop: 20,
    color: (props) => {
      if (!props?.color) return theme.palette.text.primary;
      return tinycolor(props?.color).isLight() ? '#000000' : '#ffffff';
    },
  },
  editIcon: {
    color: (props) => {
      if (!props?.color) return theme.palette.text.primary;
      return tinycolor(props?.color).isLight() ? '#000000' : '#ffffff';
    },
  },
  carbonModeWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 14,
    color: theme.palette.text.primary,
    justifyContent: 'space-between',
  },
  carbonSwitch: {
    marginRight: 16,
  },
  itemRoot: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  itemGutters: {
    paddingLeft: 24,
    paddingRight: 24,
    justifyContent: 'space-between',
  },
  iconRoot: {
    paddingLeft: 6,
  },
  itemTypography: {
    fontSize: '14px',
    fontWeight: 500,
  },
  dividerRoot: {
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 14,
  },
  logoutTypographyRoot: {
    paddingLeft: 6,
    fontSize: '14px',
    fontWeight: 500,
    color: '#ff0000',
    textDecoration: 'none',
  },
  linkRoot: {
    textDecoration: 'none !important',
    color: theme.palette.text.primary,
  },
  notificationLinkRoot: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    width: '100%',
  },
  quickAccess: {
    display: 'flex',
    justifyContent: 'space-evenly',
    fontSize: 12,
  },
  quickAccessLink: {
    color: theme.palette.text.primary,
  },
  notificationsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  notificationLabel: {
    display: 'flex',
  },
  notificationWarn: {
    backgroundColor: 'red',
    padding: '3px 6px',
    borderRadius: 20,
    color: '#ffffff',
    fontSize: 14,
    minWidth: 26,
    textAlign: 'center',
    marginLeft: 10,
  },
  premiumItemContainer: {
    display: 'flex',
    alignItems: 'center',
    textWrap: 'wrap',
  },
  activeBadge: {
    border: '2px solid #37BC98',
    borderRadius: 30,
    display: 'flex',
    marginLeft: 10,
    marginRight: 10,
    padding: '4px 15px',
  },
  activeIcon: {
    marginRight: 10,
    display: 'flex',
  },
  activeLabel: {
    color: '#37BC98',
    fontSize: 14,
    fontFamily: 'Roboto',
    display: 'flex',
  },
  spotlightDailyLabel: {
    display: 'flex',
    color: theme.palette.text.primary,
  },
  spotlightDailyWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
  },
  spotlightDailyChildrenLabel: {
    display: 'flex',
    marginTop: 10,
  },
  leftContent: {
    display: 'flex',
    alignItems: 'center',
  },
  rightContent: {
    display: 'flex',
  },
  arrow: {
    width: 14,
    color: theme.palette.text.primary,
  },
}));

const providers = {
  apple: 'Apple',
  google: 'Google',
};
const provider = (value) => providers[value] || value;

const AvatarMenuItems = React.forwardRef(({ user, onClick, notificationsCount = 0 }) => {
  const userBranding = user.profile?.branding;
  const classes = userBranding ? styles(userBranding) : styles();
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const userId = user.profile?.id;

  const spotlightDailyQuery = useQuery('spotlightDailyGetSettings', getSettings, {
    variables: [userId],
  });
  const spotlightDailyData = spotlightDailyQuery?.data?.data;
  const [spotlightDailyMorning, setSpotlightDailyMorning] = useState(null);
  const [spotlightDailyEvening, setSpotlightDailyEvening] = useState(null);
  const [changeDailyNotification, setChangeDailyNotification] = useState(null);

  const settings = {
    morning_digest: spotlightDailyMorning,
    evening_digest: spotlightDailyEvening,
  };

  const spotlightDailyUpdateQuery = useQuery('spotlightDailyUpdateSettings', updateSettings, {
    variables: [userId, settings],
    enabled: false,
  });

  useEffect(() => {
    if (spotlightDailyQuery.isFetched) {
      setSpotlightDailyMorning(spotlightDailyData?.morning_digest);
      setSpotlightDailyEvening(spotlightDailyData?.evening_digest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spotlightDailyData]);

  useEffect(() => {
    if (changeDailyNotification !== null || spotlightDailyEvening != null) {
      spotlightDailyUpdateQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeDailyNotification]);

  const notificationsCountLabel = () => {
    if (notificationsCount < 1) return false;
    return notificationsCount > 9 ? '9+' : notificationsCount;
  };

  return (
    <>
      <MenuItem
        classes={{ root: classes.avatarItemRoot, gutters: classes.itemGutters }}
        onClick={onClick}
        style={{ padding: 0 }}
      >
        <EditProfileDialog profile={user.profile}>
          {({ open }) => (
            <Box flex="1" className={classes.avatarWrapper} onClick={open}>
              <Avatar user={user} classes={{ root: classes.avatarRoot }} />
              <Box flex="1">
                <Typography classes={{ root: classes.avatarNameTypography }}>
                  {(!user.profile?.email) ? 'Signed in as Guest' : user.profile.username}
                </Typography>
                <Typography classes={{ root: classes.avatarEmailTypography }}>
                  {(user.profile.provider) ? `Signed in with ${provider(user.profile.provider)}` : user.profile.email}
                </Typography>
                {userBranding && (
                  <Typography classes={{ root: classes.brandTypography }}>
                    {userBranding?.title}
                  </Typography>
                )}
              </Box>
              {user.profile?.email && (
                <>
                  <IconButton aria-label="edit profile" title="Edit profile">
                    <EditIcon className={classes.editIcon} />
                  </IconButton>
                </>
              )}
            </Box>
          )}
        </EditProfileDialog>
      </MenuItem>
      <Divider classes={{ root: classes.dividerRoot }} />
      <Box flex="1" className={classes.carbonModeWrapper}>
        <Box className={classes.leftContent}>
          <ListItemIcon classes={{ root: classes.iconRoot }}>
            <Brightness6Icon />
          </ListItemIcon>
          <Typography classes={{ root: classes.itemTypography }}>Theme</Typography>
          <Switch
            size="small"
            classes={{
              root: classes.carbonSwitch,
            }}
            checked={darkMode}
            onChange={() => setDarkMode(!darkMode)}
          />
        </Box>
        <Box className={classes.rightContent} />
      </Box>
      <Link
        to={!user.profile?.email ? routes.auth.signup : routes.premium}
        classes={{ root: classes.linkRoot }}
      >
        <MenuItem
          classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
          onClick={onClick}
        >
          <Box className={classes.leftContent}>
            <ListItemIcon classes={{ root: classes.iconRoot }}>
              {(user.profile?.subscription?.premium)
                ? (<PremiumIcon color={darkMode ? '#ffffff' : '#757575'} />)
                : (<SpotlightLogo small height={25} />)}
            </ListItemIcon>
            <Box classes={{ root: classes.itemTypography }}>
              {user.profile?.subscription?.premium
                ? (
                  <Box className={classes.premiumItemContainer}>
                    <span>SpotlightNews® Premium+</span>
                    <Box className={classes.activeBadge}>
                      <Box className={classes.activeIcon}><ActiveIcon /></Box>
                      <Box className={classes.activeLabel}>Active</Box>
                    </Box>
                  </Box>
                )
                : (
                  <Box style={{ color: '#37bc98' }}>Upgrade Account{(!user.profile?.email) && ' Guest Account'}</Box>
                )}
            </Box>
          </Box>
          <Box className={classes.rightContent}>
            <ArrowForwardIosIcon classes={{ root: classes.arrow }} />
          </Box>
        </MenuItem>
      </Link>
      <Link to={routes.bookmarks} classes={{ root: classes.linkRoot }}>
        <MenuItem
          classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
          onClick={onClick}
        >
          <Box className={classes.leftContent}>
            <ListItemIcon classes={{ root: classes.iconRoot }}>
              <BookmarkIcon />
            </ListItemIcon>
            <Typography classes={{ root: classes.itemTypography }}>Bookmarks</Typography>
          </Box>
          <Box className={classes.rightContent}>
            <ArrowForwardIosIcon classes={{ root: classes.arrow }} />
          </Box>
        </MenuItem>
      </Link>
      <Link to={routes.favorites} classes={{ root: classes.linkRoot }}>
        <MenuItem
          classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
          onClick={onClick}
        >
          <Box className={classes.leftContent}>
            <ListItemIcon classes={{ root: classes.iconRoot }}>
              <FavoriteIcon />
            </ListItemIcon>
            <Typography classes={{ root: classes.itemTypography }}>Liked</Typography>
          </Box>
          <Box className={classes.rightContent}>
            <ArrowForwardIosIcon classes={{ root: classes.arrow }} />
          </Box>
        </MenuItem>
      </Link>
      {user.profile?.email && (
        <Link to={routes.downloadHistory} classes={{ root: classes.linkRoot }}>
          <MenuItem
            classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
            onClick={onClick}
          >
            <Box className={classes.leftContent}>
              <ListItemIcon classes={{ root: classes.iconRoot }}>
                <GetAppIcon />
              </ListItemIcon>
              <Typography classes={{ root: classes.itemTypography }}>Download History</Typography>
            </Box>
            <Box className={classes.rightContent}>
              <ArrowForwardIosIcon classes={{ root: classes.arrow }} />
            </Box>
          </MenuItem>
        </Link>
      )}
      <Link to={routes.read} classes={{ root: classes.linkRoot }}>
        <MenuItem
          classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
          onClick={onClick}
        >
          <Box className={classes.leftContent}>
            <ListItemIcon classes={{ root: classes.iconRoot }}>
              <RestoreIcon />
            </ListItemIcon>
            <Typography classes={{ root: classes.itemTypography }}>Recently Read</Typography>
          </Box>
          <Box className={classes.rightContent}>
            <ArrowForwardIosIcon classes={{ root: classes.arrow }} />
          </Box>
        </MenuItem>
      </Link>

      <MenuItem
        classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
        onClick={onClick}
      >
        <Box className={classes.leftContent}>
          <Link to={routes.notifications} classes={{ root: classes.notificationLinkRoot }}>
            <Box className={classes.notificationsContainer}>

              <Box className={classes.notificationLabel}>
                <ListItemIcon classes={{ root: classes.iconRoot }}>
                  <NotificationsIcon />
                </ListItemIcon>
                <Typography classes={{ root: classes.itemTypography }}>Notifications</Typography>
              </Box>

              {notificationsCountLabel() && (

                <Box className={classes.notificationWarn}>{notificationsCountLabel()}</Box>

              )}
            </Box>
          </Link>
        </Box>
        <Box className={classes.rightContent}>
          <ArrowForwardIosIcon classes={{ root: classes.arrow }} />
        </Box>
      </MenuItem>

      <MenuItem
        classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
      >
        <Box>
          <Box className={classes.spotlightDailyLabel}>
            <ListItemIcon classes={{ root: classes.iconRoot }}>
              <NotificationsIcon />
            </ListItemIcon>
            <Typography classes={{ root: classes.itemTypography }}>Spotlight Daily</Typography>
          </Box>
          <MenuItem
            classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
          >
            <Box className={classes.childrenItens}>
              <Box className={classes.spotlightDailyChildrenLabel}>
                <Box flex="1" className={classes.spotlightDailyWrapper}>
                  <ListItemIcon classes={{ root: classes.iconRoot }}>
                    <WbSunny />
                  </ListItemIcon>
                  <Typography classes={{ root: classes.itemTypography }}>Morning</Typography>
                  <Switch
                    size="small"
                    classes={{
                      root: classes.carbonSwitch,
                    }}
                    checked={spotlightDailyMorning}
                    onChange={() => {
                      setSpotlightDailyMorning(!spotlightDailyMorning);
                      setChangeDailyNotification(!changeDailyNotification);
                    }}
                  />
                </Box>
              </Box>
              <Box className={classes.spotlightDailyChildrenLabel}>
                <Box flex="1" className={classes.spotlightDailyWrapper}>
                  <ListItemIcon classes={{ root: classes.iconRoot }}>
                    <NightsStay />
                  </ListItemIcon>
                  <Typography classes={{ root: classes.itemTypography }}>Evening</Typography>
                  <Switch
                    size="small"
                    classes={{
                      root: classes.carbonSwitch,
                    }}
                    checked={spotlightDailyEvening}
                    onChange={() => {
                      setSpotlightDailyEvening(!spotlightDailyEvening);
                      setChangeDailyNotification(!changeDailyNotification);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </MenuItem>
        </Box>
      </MenuItem>

      <SettingsDialog profile={user.profile}>
        {({ open }) => (
          <MenuItem
            classes={{ root: classes.avatarItemRoot, gutters: classes.itemGutters }}
            className={classes.linkRoot}
            onClick={() => { onClick(); open(); }}
          >
            <Box className={classes.leftContent}>
              <ListItemIcon classes={{ root: classes.iconRoot }}>
                <SettingsIcon />
              </ListItemIcon>
              <Typography classes={{ root: classes.itemTypography }}>Settings</Typography>
            </Box>
            <Box className={classes.rightContent}>
              <ArrowForwardIosIcon classes={{ root: classes.arrow }} />
            </Box>
          </MenuItem>
        )}
      </SettingsDialog>
      <a href="mailto: wecare@spotlightnews.us" className={classes.linkRoot}>
        <MenuItem
          classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
          onClick={onClick}
        >
          <Box className={classes.leftContent}>
            <ListItemIcon classes={{ root: classes.iconRoot }}>
              <HelpIcon />
            </ListItemIcon>
            <Typography classes={{ root: classes.itemTypography }}>Help & Feedback</Typography>
          </Box>
          <Box className={classes.rightContent}>
            <ArrowForwardIosIcon classes={{ root: classes.arrow }} />
          </Box>
        </MenuItem>
      </a>
      {user.profile?.email && (
        <Link to={routes.auth.signout} style={{ textDecoration: 'none' }}>
          <MenuItem
            classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
            onClick={onClick}
          >
            <Typography classes={{ root: classes.logoutTypographyRoot }}>Logout</Typography>
          </MenuItem>
        </Link>
      )}
      <Box className={classes.quickAccess}>
        <Box>
          <Link className={classes.quickAccessLink} href="https://spotlightnews.us/terms.html" target="_blank">Terms and Conditions</Link>
        </Box>
        <Box>
          <Link className={classes.quickAccessLink} href="https://spotlightnews.us/privacy.html" target="_blank">Privacy Policy</Link>
        </Box>
        <Box>
          <Link className={classes.quickAccessLink} href="https://spotlightnews.us/licenses.html" target="_blank">Licenses</Link>
        </Box>
      </Box>
    </>
  );
});

AvatarMenuItems.propTypes = {
  user: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  notificationsCount: PropTypes.number,
};

AvatarMenuItems.displayName = 'AvatarMenuItems';

export default AvatarMenuItems;
