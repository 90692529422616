import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  AppBar, Container, makeStyles, Box, Typography, Link,
} from '@material-ui/core';

import SpotlightLogo from '../../components/common/SpotlightLogo';
import routes from '../../routes';
import Alert from '../../components/Alert';

const styles = makeStyles((theme) => ({
  appBarRoot: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    paddingTop: 25,
    paddingBottom: 25,
    borderWidth: '0 0 1px 0',
    borderStyle: 'solid',
    borderColor: 'rgba(112,112,112, .3)',
    textAlign: 'center',
  },
  containerRoot: {
    backgroundColor: theme.palette.background.default,
  },
  content: {
    maxWidth: 430,
    marginTop: 60,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    paddingBottom: theme.spacing(6),
  },
  slogan: {
    fontStyle: 'italic',
    color: theme.palette.text.primary,
  },
}));

const SignedOutLayout = ({ component: Component, isSignedIn, allowSigned, ...rest }) => {
  const classes = styles();
  const location = useLocation();
  const flash = location.state?.flash;

  if (isSignedIn && !allowSigned) {
    return (
      <Redirect
        to={{
          pathname: routes.news.main,
          state: { flash: { message: 'You are already signed in.', severity: 'warning' } },
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <AppBar position="relative" classes={{ root: classes.appBarRoot }}>
            <Container classes={{ root: classes.containerRoot }}>
              <Link href={routes.news.main}>
                <SpotlightLogo height={55} />
              </Link>
              <Typography className={classes.slogan}>
                The best information experience ever, seriously.
              </Typography>
            </Container>
          </AppBar>
          <Container component="main">
            <Box className={classes.content}>
              {flash ? <Alert {...flash} /> : null}
              <Component {...matchProps} />
            </Box>
          </Container>
        </>
      )}
    />
  );
};

SignedOutLayout.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  allowSigned: PropTypes.bool,
  component: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  isSignedIn: state.user.isSignedIn,
});

export default connect(mapStateToProps)(SignedOutLayout);
