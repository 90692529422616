import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Container, Grid, Hidden, IconButton, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { format as formatDate } from 'date-fns';
import { useQuery } from '../../hooks/useQuery';
import { getStats, updateStatsSettings } from '../../actions/stats';
import StreakIcon from './StreakIcon';
import ArticlesReadIcon from './ArticlesReadIcon';
import ReadTimeIcon from './ReadTimeIcon';
import CurrentStreakIcon from './CurrentStreakIcon';
import MyStatsGoalTracker from './MyStatsGoalTracker';
import ArticlesSharedIcon from './ArticlesSharedIcon';
import TopicsSharedIcon from './TopicsSharedIcon';
import CustomTopicSharedIcon from './CustomTopicSharedIcon';
import PublisherSharedIcon from './PublisherSharedIcon';
import ReadPercentageIcon from './ReadPercentageIcon';
import ThemeContext from '../../context/theme/ThemeContext';
import { reportAndRenderError } from '../../utils/errors';

const styles = makeStyles((theme) => ({
  containerRoot: {
    background: theme.palette.background.default,
    paddingTop: 15,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  tabItem: {
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      padding: 5,
    },
    '& > .MuiTab-wrapper': {
      minWidth: 80,
    },
  },
  title: {
    textAlign: 'center',
    paddingTop: 15,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 5,
    },
  },
  titleContainer: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
    '& > p': {
      fontSize: 18,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
      marginLeft: 10,
      color: theme.palette.text.main,
      textTransform: 'uppercase',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  statsCard: {
    backgroundColor: theme.palette.background.paper,
    margin: 10,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  statsCardIcon: {
    color: theme.palette.primary.main,
  },
  value: {
    fontSize: 50,
    textAlign: 'center',
    color: '#37BC98',
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
  },
  grindSingleLine: {
    marginTop: 30,
    [theme.breakpoints.down('xs')]: {
      marginTop: 15,
    },
  },
  singleLineTitle: {
    fontSize: 20,
    textAlign: 'left',
  },
  readingTimeBox: {
    padding: '50px 15px',
    textAlign: 'left',
  },
  readingTimeBoxGrid: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  graphBox: {
    padding: '30px 0px',
  },
  readingTimeGoal: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      paddingRight: 10,
    },
  },
  newReadingTime: {
    display: 'flex',
    alignItems: 'baseline',
    fontSize: 20,
    textAlign: 'left',
  },
  newReadingTimeInput: {
    marginLeft: 10,
    marginRight: 5,
    border: '1px solid #000',
    padding: 10,
    width: 80,
  },
  timezoneInput: {
    width: 200,
    marginLeft: 10,
    marginRight: 5,
    border: '1px solid #000',
    padding: 10,
  },
  newSaveButton: {
    marginLeft: 10,
  },
}));

const TabPanel = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

const MyStatsPanel = ({ userId, webView = false }) => {
  const classes = styles();
  const [value, setValue] = useState(0);
  const [showReadingTimeEdit, setShowReadingTimeEdit] = useState(false);
  const [showArticleReadEdit, setShowArticleReadEdit] = useState(false);
  const [showTimezoneEdit, setShowTimezoneEdit] = useState(false);
  const [readingTimeDailyGoal, setReadingTimeDailyGoal] = useState(null);
  const [articleReadsDailyGoal, setArticleReadsDailyGoal] = useState(null);
  const [readingPercentage, setReadingPercentage] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const readingTimeInputRef = useRef();
  const articleReadInputRef = useRef();
  const timezoneInputRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const { darkMode, setDarkMode } = useContext(ThemeContext);

  const statsQuery = useQuery('fetchInterestsSubscription', getStats, {
    variables: [userId],
  });
  const stats = statsQuery?.data;

  const updateStatsSettingsQuery = useQuery('updateStatsSettings', updateStatsSettings, {
    variables: [userId, readingTimeDailyGoal, articleReadsDailyGoal, timezone],
    enabled: false,
  });

  const a11yProps = (index) => ({
    id: `mystats-tab-${index}`,
    'aria-controls': `mystats-tab-${index}`,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getWeekday = (date) => {
    if (!date) return null;
    const rDate = new Date(`${date} 00:00:00`);
    return formatDate(rDate, 'EEEE');
  };

  const graphData = stats?.recent_days?.filter((rD) => rD !== null).map((recentDay) => ({
    days: getWeekday(recentDay?.date)?.slice(0, 3),
    'Articles Read': recentDay?.article_reads_count,
    goal: 'Goal',
    'Personal Goal': articleReadsDailyGoal,
  }));

  const saveNewReadingTime = async (e) => {
    e.preventDefault();
    if (readingTimeInputRef.current.value <= 0) {
      reportAndRenderError('Reagind Time should be higher than zero.');
      return false;
    }
    setReadingTimeDailyGoal(readingTimeInputRef.current.value * 60);
    setShowReadingTimeEdit(false);
    return true;
  };

  const saveNewArticleRead = async (e) => {
    e.preventDefault();
    if (articleReadInputRef.current.value <= 0) {
      reportAndRenderError('Articles Read should be higher than zero.');
      return false;
    }
    setArticleReadsDailyGoal(articleReadInputRef.current.value);
    setShowArticleReadEdit(false);
    return true;
  };

  const saveNewTimezone = async (e) => {
    e.preventDefault();
    setTimezone(timezoneInputRef.current.value);
    setShowTimezoneEdit(false);
  };

  // eslint-disable-next-line max-len
  const checkIfTime = (date) => (date?.hours !== null || date?.minutes !== null || date?.seconds !== null);

  useEffect(() => {
    setReadingTimeDailyGoal(stats?.user_stats_settings?.reading_time_daily_goal);
    setArticleReadsDailyGoal(stats?.user_stats_settings?.article_reads_daily_goal);
    setTimezone(stats?.user_stats_settings?.timezone);
  }, [stats?.user_stats_settings]);

  useEffect(() => {
    if (stats?.user_stats_settings) {
      if (readingTimeDailyGoal !== stats?.user_stats_settings?.reading_time_daily_goal) {
        updateStatsSettingsQuery.refetch();
        statsQuery.refetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readingTimeDailyGoal]);

  useEffect(() => {
    if (stats?.user_stats_settings) {
      if (articleReadsDailyGoal !== stats?.user_stats_settings?.article_reads_daily_goal) {
        updateStatsSettingsQuery.refetch();
        statsQuery.refetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleReadsDailyGoal]);

  useEffect(() => {
    if (stats?.user_stats_settings) {
      if (timezone !== stats?.user_stats_settings?.timezone) {
        updateStatsSettingsQuery.refetch();
        statsQuery.refetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  useEffect(() => {
    const rDays = stats?.recent_days?.slice(-1)[0];
    const daysTotal = parseInt(rDays?.days_total, 10);
    const readingDaysTotal = parseInt(rDays?.reading_days_total, 10);
    if (daysTotal > 0) {
      setReadingPercentage(Math.round((readingDaysTotal / daysTotal) * 100));
    } else {
      setReadingPercentage(0);
    }
  }, [stats?.recent_days]);
  // eslint-disable-next-line max-len

  return (
    <Container classes={{ root: classes.containerRoot }}>
      {!webView && (
        <Typography variant="h4" className={classes.title}>
          MyStats
        </Typography>
      )}
      <Box className={classes.tabContainer}>
        <Tabs value={value} onChange={handleChange} aria-label="mystats-tabs">
          <Tab key="tab-reading" className={classes.tabItem} label="Reading" {...a11yProps(0)} />
          <Tab key="tab-sharing" className={classes.tabItem} label="Activities" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel key="tabPanel-reading" value={value} index={0}>
        <Grid
          container
          spacing={3}
          className={classes.row}
        >
          <Grid
            className={classes.statsCard}
            item
            xs={4}
            sm={4}
            md={3}
            lg={3}
            style={{ '-webkit-filter': stats?.today?.current_streak_days !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <CurrentStreakIcon />
              <Typography>Current Streak</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && stats?.today?.current_streak_days !== null ? stats?.today?.current_streak_days : ''}
              {!statsQuery?.isFetching && stats?.today?.current_streak_days === null ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
          <Grid
            className={classes.statsCard}
            item
            xs={4}
            sm={4}
            md={3}
            lg={3}
            style={{ '-webkit-filter': stats?.today?.max_streak_days !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <StreakIcon />
              <Typography>Max Streak</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && stats?.today?.max_streak_days !== null ? stats?.today?.max_streak_days : ''}
              {!statsQuery?.isFetching && stats?.today?.max_streak_days === null ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
          <Grid
            className={classes.statsCard}
            item
            xs={4}
            sm={4}
            md={3}
            lg={3}
            style={{ '-webkit-filter': readingPercentage !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <ReadPercentageIcon />
              <Typography>Read %</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && readingPercentage !== null ? `${readingPercentage} %` : ''}
              {!statsQuery?.isFetching && readingPercentage === null ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.row}>
          <Grid
            className={classes.statsCard}
            item
            xs={4}
            sm={4}
            md={3}
            lg={3}
            style={{ '-webkit-filter': stats?.today?.article_reads_count !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <ArticlesReadIcon />
              <Typography>Read Today</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && stats?.today?.article_reads_count !== null ? stats?.today?.article_reads_count : ''}
              {!statsQuery?.isFetching && stats?.today?.article_reads_count === null ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
          <Grid
            className={classes.statsCard}
            item
            xs={4}
            sm={4}
            md={3}
            lg={3}
            style={{ '-webkit-filter': stats?.this_month?.article_reads_count !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <ArticlesReadIcon />
              <Typography>Read This Month</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && stats?.this_month?.article_reads_count !== null ? stats?.this_month?.article_reads_count : ''}
              {!statsQuery?.isFetching && stats?.this_month?.article_reads_count === null ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
          <Grid
            className={classes.statsCard}
            item
            xs={4}
            sm={4}
            md={3}
            lg={3}
            style={{ '-webkit-filter': stats?.last_month !== null && stats?.last_month?.article_reads_count !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <ArticlesReadIcon />
              <Typography>Read Last Month</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && stats?.last_month?.article_reads_count !== null ? stats?.last_month?.article_reads_count : ''}
              {!statsQuery?.isFetching && (stats?.last_month === null || stats?.last_month?.article_reads_count === null) ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.row}>
          <Grid
            className={classes.statsCard}
            item
            xs={4}
            sm={4}
            md={3}
            lg={3}
            style={{ '-webkit-filter': checkIfTime(stats?.today?.reading_time_in_units) ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <ReadTimeIcon />
              <Typography>Time Today</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && checkIfTime(stats?.today?.reading_time_in_units) && (
                <span style={{ display: stats?.today ? 'block' : 'none' }}>
                  {stats?.today?.reading_time_in_units?.hours > 0
                    && `${stats?.today?.reading_time_in_units?.hours}h `}
                  {stats?.today?.reading_time_in_units?.minutes}m&nbsp;
                  {stats?.today?.reading_time_in_units?.seconds}s
                </span>
              )}
              {!statsQuery?.isFetching
                && !checkIfTime(stats?.today?.reading_time_in_units) && (
                  <span>-</span>
              )}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
          <Grid
            className={classes.statsCard}
            item
            xs={4}
            sm={4}
            md={3}
            lg={3}
            style={{ '-webkit-filter': checkIfTime(stats?.this_month?.reading_time_in_units) ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <ReadTimeIcon />
              <Typography>Time This Month</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && checkIfTime(stats?.this_month?.reading_time_in_units) && (
                <span style={{ display: stats?.this_month ? 'block' : 'none' }}>
                  {stats?.this_month?.reading_time_in_units?.hours > 0
                    && `${stats?.this_month?.reading_time_in_units?.hours}h `}
                  {stats?.this_month?.reading_time_in_units?.minutes}m&nbsp;
                  {stats?.this_month?.reading_time_in_units?.seconds}s
                </span>
              )}
              {!statsQuery?.isFetching
                && !checkIfTime(stats?.this_month?.reading_time_in_units) && (
                  <span>-</span>
              )}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
          <Grid
            className={classes.statsCard}
            item
            xs={4}
            sm={4}
            md={3}
            lg={3}
            style={{ '-webkit-filter': (!stats?.last_month || !checkIfTime(!stats?.last_month?.reading_time_in_units)) ? 'grayscale(1)' : 'none' }}
          >
            <Box className={classes.titleContainer}>
              <ReadTimeIcon />
              <Typography>Time Last Month</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && checkIfTime(stats?.last_month?.reading_time_in_units) && (
                <span style={{ display: stats?.last_month ? 'block' : 'none' }}>
                  {stats?.last_month?.reading_time_in_units?.hours > 0
                    && `${stats?.this_month?.reading_time_in_units?.hours}h `}
                  {stats?.last_month?.reading_time_in_units?.minutes}m&nbsp;
                  {stats?.last_month?.reading_time_in_units?.seconds}s
                </span>
              )}
              {!statsQuery?.isFetching
                // eslint-disable-next-line max-len
                && (!stats?.last_month || !checkIfTime(!stats?.last_month?.reading_time_in_units)) && (
                  <span>-</span>
              )}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.row}>
          <Grid className={classes.grindSingleLine} item xs={12} sm={12} md={12} lg={12}>
            <Typography className={classes.singleLineTitle}>Goal Tracker - Reading Time</Typography>
            <Box className={classes.readingTimeBox}>
              <Grid container spacing={3} style={{ display: stats?.recent_days ? 'flex' : 'none' }} className={classes.readingTimeBoxGrid}>
                <Hidden lgUp>
                  <MyStatsGoalTracker
                    days={stats?.recent_days}
                    readingTimeDailyGoal={stats?.user_stats_settings?.reading_time_daily_goal}
                    isMobile
                  />
                </Hidden>
                <Hidden mdDown>
                  <MyStatsGoalTracker
                    days={stats?.recent_days}
                    readingTimeDailyGoal={stats?.user_stats_settings?.reading_time_daily_goal}
                  />
                </Hidden>
              </Grid>
              {!stats?.recent_days && (
                <center>
                  <CircularProgress />
                </center>
              )}
            </Box>
          </Grid>
          <Grid className={classes.grindSingleLine} item xs={12} sm={12} md={12} lg={12}>
            <Typography className={classes.singleLineTitle}>
              Goal Tracker - Articles Read
            </Typography>
            <Box className={classes.graphBox} style={{ display: graphData?.length > 0 ? 'flex' : 'none' }}>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <LineChart
                    data={graphData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="days" stroke={darkMode ? '#ffffff' : '#000000'} />
                    <YAxis dateKey="goal" stroke={darkMode ? '#ffffff' : '#000000'} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Personal Goal" stroke="#186AF4" dot={false} strokeWidth={1.5} />
                    <Line type="monotone" dataKey="Articles Read" stroke="#37bc98" dot={false} strokeWidth={1.5} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Box>
            {!graphData && (
              <center>
                <CircularProgress />
              </center>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {!showReadingTimeEdit && (
              <Box class={classes.readingTimeGoal}>
                <Typography className={classes.singleLineTitle}>
                  Daily Reading Time Goal Settings: {Math.ceil(readingTimeDailyGoal / 60)} {Math.ceil(readingTimeDailyGoal / 60) > 1 ? 'minutes' : 'minute'}
                </Typography>
                <IconButton onClick={() => setShowReadingTimeEdit(true)}>
                  <EditIcon />
                </IconButton>
              </Box>
            )}
            {showReadingTimeEdit && (
              <Box>
                <Typography className={classes.newReadingTime}>
                  Daily Reading Time Goal Settings:  <input ref={readingTimeInputRef} id="newReadingTime" className={classes.newReadingTimeInput} type="number" /> minutes
                  <Button color="primary" className={classes.newSaveButton} variant="contained" onClick={saveNewReadingTime}>OK</Button>
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {!showArticleReadEdit && (
              <Box class={classes.readingTimeGoal}>
                <Typography className={classes.singleLineTitle}>
                  Daily Articles Read Goal Settings: {articleReadsDailyGoal} {articleReadsDailyGoal > 1 ? 'articles' : 'article'}
                </Typography>
                <IconButton onClick={() => setShowArticleReadEdit(true)}>
                  <EditIcon />
                </IconButton>
              </Box>
            )}
            {showArticleReadEdit && (
              <Box>
                <Typography className={classes.newReadingTime}>
                  Daily Articles Read Goal Settings:  <input ref={articleReadInputRef} id="newReadingTime" className={classes.newReadingTimeInput} type="number" /> articles
                  <Button color="primary" className={classes.newSaveButton} variant="contained" onClick={saveNewArticleRead}>OK</Button>
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {!showTimezoneEdit && (
              <Box class={classes.readingTimeGoal}>
                <Typography className={classes.singleLineTitle}>
                  Time Zone Settings: {timezone}
                </Typography>
                <IconButton onClick={() => setShowTimezoneEdit(true)}>
                  <EditIcon />
                </IconButton>
              </Box>
            )}
            {showTimezoneEdit && (
              <Box>
                <Typography className={classes.newReadingTime}>
                  Time Zone Settings:
                  <select ref={timezoneInputRef} className={classes.timezoneInput}>
                    <option value="Eastern Time">Eastern Time</option>
                    <option value="Central Time">Central Time</option>
                    <option value="Mountain Time">Mountain Time</option>
                    <option value="Mountain Standard Time (Arizona)">Mountain Standard Time (Arizona)</option>
                    <option value="Pacific Time">Pacific Time</option>
                  </select>
                  <Button color="primary" className={classes.newSaveButton} variant="contained" onClick={saveNewTimezone}>OK</Button>
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel key="tabPanel-sharing" value={value} index={1}>
        <Grid
          container
          spacing={3}
          className={classes.row}
        >
          <Grid
            className={classes.statsCard}
            item
            xs={6}
            sm={6}
            md={3}
            lg={3}
            style={{ '-webkit-filter': stats?.this_month?.shares?.articles_shares_count !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <ArticlesSharedIcon />
              <Typography>Articles Shared This Month</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && stats?.this_month?.shares?.articles_shares_count !== null ? stats?.this_month?.shares?.articles_shares_count : ''}
              {!statsQuery?.isFetching && stats?.this_month?.shares?.articles_shares_count === null ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
          <Grid
            className={classes.statsCard}
            item
            xs={6}
            sm={6}
            md={3}
            lg={3}
            style={{ '-webkit-filter': stats?.last_month !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <ArticlesSharedIcon />
              <Typography>Articles Shared Last Month</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && stats?.last_month !== null ? stats?.last_month?.shares?.articles_shares_count : ''}
              {!statsQuery?.isFetching && stats?.last_month === null ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          className={classes.row}
        >
          <Grid
            className={classes.statsCard}
            item
            xs={6}
            sm={6}
            md={3}
            lg={3}
            style={{ '-webkit-filter': stats?.this_month?.shares?.topics_shares_count !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <TopicsSharedIcon />
              <Typography>Topics Shared This Month</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && stats?.this_month?.shares?.topics_shares_count !== null ? stats?.this_month?.shares?.topics_shares_count : ''}
              {!statsQuery?.isFetching && stats?.this_month?.shares?.topics_shares_count === null ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
          <Grid
            className={classes.statsCard}
            item
            xs={6}
            sm={6}
            md={3}
            lg={3}
            style={{ '-webkit-filter': stats?.last_month !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <TopicsSharedIcon />
              <Typography>Topics Shared Last Month</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && stats?.last_month !== null ? stats?.last_month?.shares?.topics_shares_count : ''}
              {!statsQuery?.isFetching && stats?.last_month === null ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          className={classes.row}
        >
          <Grid
            className={classes.statsCard}
            item
            xs={6}
            sm={6}
            md={3}
            lg={3}
            style={{ '-webkit-filter': stats?.this_month?.shares?.custom_topics_created_count !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <CustomTopicSharedIcon />
              <Typography>Custom Topics Created This Month</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && stats?.this_month?.shares?.custom_topics_created_count !== null ? stats?.this_month?.shares?.custom_topics_created_count : ''}
              {!statsQuery?.isFetching && stats?.this_month?.shares?.custom_topics_created_count === null ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
          <Grid
            className={classes.statsCard}
            item
            xs={6}
            sm={6}
            md={3}
            lg={3}
            style={{ '-webkit-filter': stats?.last_month !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <CustomTopicSharedIcon />
              <Typography>Custom Topics Created Last Month</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && stats?.last_month !== null ? stats?.last_month?.shares?.custom_topics_created_count : ''}
              {!statsQuery?.isFetching && stats?.last_month === null ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          className={classes.row}
        >
          <Grid
            className={classes.statsCard}
            item
            xs={6}
            sm={6}
            md={3}
            lg={3}
            style={{ '-webkit-filter': stats?.this_month?.shares?.channels_shares_count !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <PublisherSharedIcon />
              <Typography>Publishers Shared This Month</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && stats?.this_month?.shares?.channels_shares_count !== null ? stats?.this_month?.shares?.channels_shares_count : ''}
              {!statsQuery?.isFetching && stats?.this_month?.shares?.channels_shares_count === null ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
          <Grid
            className={classes.statsCard}
            item
            xs={6}
            sm={6}
            md={3}
            lg={3}
            style={{ '-webkit-filter': stats?.last_month !== null ? 'none' : 'grayscale(1)' }}
          >
            <Box className={classes.titleContainer}>
              <PublisherSharedIcon />
              <Typography>Publishers Shared Last Month</Typography>
            </Box>
            <Box className={classes.value}>
              {!statsQuery?.isFetching && stats?.last_month !== null ? stats?.last_month?.shares?.channels_shares_count : ''}
              {!statsQuery?.isFetching && stats?.last_month === null ? '-' : ''}
              {statsQuery?.isFetching && (<CircularProgress />)}
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
    </Container>
  );
};

MyStatsPanel.propTypes = {
  userId: PropTypes.shape(),
  webView: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  userId: state.user?.profile?.id,
});

export default connect(mapStateToProps)(MyStatsPanel);
