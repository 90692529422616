import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const MyStatsIcon = ({ color = '#ffffff', mobile = false }) => (
  <Container
    style={{
      display: 'inline-block',
      width: 30,
      padding: 0,
      margin: mobile ? '0px 15px 0px 0px' : 0,
      marginRight: 10,
    }}
  >
    <TrendingUpIcon />
  </Container>
);

MyStatsIcon.propTypes = {
  color: PropTypes.string,
  mobile: PropTypes.bool,
};

export default MyStatsIcon;
