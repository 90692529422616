import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import { bracketChallenge } from '../../actions/promotions';

const styles = makeStyles((theme) => ({
  menuImageMobileRoot: {
    marginRight: 0,
  },
  menuImageDesktopRoot: {
    marginRight: 20,
  },
}));

const BracketChallengeIcon = ({ user, mobile = false }) => {
  const classes = styles();
  const [formMethod, setFormMethod] = useState(null);
  const [formAction, setFormAction] = useState(null);
  const [formData, setFormData] = useState([]);
  const formRef = useRef(null);

  const iconClick = async () => {
    const data = await bracketChallenge(user?.profile?.id);

    if (data.status === 403) {
      toast.error('Guest users should register first.');
    } else {
      setFormMethod(data?.method);
      setFormAction(data?.url);
      setFormData(Object.entries(data?.parameters));
    }
  };

  useEffect(() => {
    if (formData.length > 0) formRef.current.submit();
  }, [formData]);

  return (
    <>
      <form action={formAction} ref={formRef} method={formMethod}>
        {formData.map((fData) => <input type="hidden" name={fData[0]} value={fData[1]} />)}
      </form>
      <Button
        className={mobile ? classes.menuImageMobileRoot : classes.menuImageDesktopRoot}
        onClick={iconClick}
      >
        <img
          alt=""
          width="50"
          src={user?.profile?.current_contest?.button_image}
        />
      </Button>
    </>
  );
};

BracketChallengeIcon.propTypes = {
  user: PropTypes.shape().isRequired,
  mobile: PropTypes.bool,
};

export default BracketChallengeIcon;
