import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Menu,
} from '@material-ui/core';

import AvatarMenuItems from './AvatarMenuItems';

const styles = makeStyles((theme) => ({
  paper: {
    color: '#000',
    marginTop: 30,
    width: 'auto',
    borderRadius: 0,
  },
  menuListPadding: {
    paddingTop: 0,
    paddingBottom: 11,
  },
}));

const AvatarMenu = ({
  anchorEl, open, onClose, user, notificationsCount = 0,
}) => {
  const classes = styles();
  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      classes={{ paper: classes.paper }}
      onClose={onClose}
      anchorOrigin={{ horizontal: -330, vertical: 20 }}
      keepMounted
      MenuListProps={{ classes: { padding: classes.menuListPadding } }}
    >
      <AvatarMenuItems user={user} onClick={onClose} notificationsCount={notificationsCount} />
    </Menu>
  );
};

AvatarMenu.propTypes = {
  user: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  notificationsCount: PropTypes.number,
  open: PropTypes.bool,
  anchorEl: PropTypes.shape(),
};

export default AvatarMenu;
