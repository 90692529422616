import client from '../utils/client';

const bracketChallenge = async (userId) => {
  const url = `/contests/${userId}/bracket_challenge`;
  try {
    const response = await client().get(url);
    const { data } = response;
    return data;
  } catch (err) {
    return err;
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  bracketChallenge,
};
