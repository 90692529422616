import React from 'react';
import { withRouter } from 'react-router-dom';
import MyStatsPanel from '../../components/MyStats/MyStatsPanel';

const MyStats = () => (
  <>
    <MyStatsPanel />
  </>
);

export default withRouter(MyStats);
